import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import MetabolicRateCalculator from './MetabolicRateCalculator';
import Header from './Header';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 200px)' }}>
        {/* Adjust 200px based on your Header and Footer heights */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/calculator" element={<MetabolicRateCalculator />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
