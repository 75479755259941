// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#F9F7F3', // Background color
    },
    primary: {
      main: '#68a16c', // Primary color for buttons, etc.
      contrastText: '#ffffff', // Text color on primary backgrounds
    },
    secondary: {
      main: '#647611', // Secondary color
    },
    text: {
      primary: '#333333', // Default text color
    },
  },
  typography: {
    fontFamily: 'DM Sans, sans-serif',
    h2: {
      fontWeight: "600",
      color: '#68a16c',
      fontSize: '4rem',
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      fontWeight: 700,
      color: '#68a16c',
      fontSize: '2rem',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.75rem',
      '@media (max-width:600px)': {
        fontSize: '1.3rem',
      },
    },
    body1: {
      fontSize: '1.1rem',
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
  },
});

export default theme;