// src/Footer.js

import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, FormControl, Select, MenuItem } from "@mui/material";

function Footer() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 2,
        mt: { xs: 4, sm: 6 },
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        position: "relative",
        textAlign: { xs: "center", sm: "left" },
      }}
    >
      {/* Centered Text */}
      <Typography variant="body2" color="textSecondary">
        {t("built_by")}{" "}
        <a href="https://github.com/ygorsecco" target="_blank" rel="noopener noreferrer">
          {t("ygor_secco")}
        </a>
      </Typography>

      {/* Right: Language Selector */}
      <Box
        sx={{
          position: { xs: "static", sm: "absolute" },
          right: { sm: 16 },
          mt: { xs: 1, sm: 0 },
        }}
      >
        <FormControl variant="standard" sx={{ minWidth: 80 }}>
          <Select value={i18n.language} onChange={handleLanguageChange} disableUnderline>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="es">Español</MenuItem>
            <MenuItem value="pt">Português</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default Footer;
