import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "./theme"; // Import theme if needed

function MetabolicRateCalculator() {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    age: "",
    height: "",
    weight: "",
    gender: "",
    activityLevel: "",
  });

  // State variables
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [activityLevel, setActivityLevel] = useState("");
  const [result, setResult] = useState(null);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  // State for modal
  const [open, setOpen] = useState(false);

  const activityFactors = {
    sedentary: 1.2,
    low: 1.375,
    moderate: 1.55,
    intense: 1.725,
  };

  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = t("error_required");
    if (!email) {
      newErrors.email = t("error_required");
    } else {
      // Simple email regex for validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = t("error_invalid_email");
      }
    }
    if (!age) {
      newErrors.age = t("error_required");
    } else if (parseFloat(age) <= 0) {
      newErrors.age = t("error_positive_number");
    }
    if (!height) {
      newErrors.height = t("error_required");
    } else if (parseFloat(height) <= 0) {
      newErrors.height = t("error_positive_number");
    }
    if (!weight) {
      newErrors.weight = t("error_required");
    } else if (parseFloat(weight) <= 0) {
      newErrors.weight = t("error_positive_number");
    }
    if (!gender) newErrors.gender = t("error_required");
    if (!activityLevel) newErrors.activityLevel = t("error_required");

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const calculateMetabolicRate = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const ageNum = parseFloat(age);
    const heightNum = parseFloat(height);
    const weightNum = parseFloat(weight);

    let BMR;

    if (gender === "male") {
      BMR = 10 * weightNum + 6.25 * heightNum - 5 * ageNum + 5;
    } else {
      BMR = 10 * weightNum + 6.25 * heightNum - 5 * ageNum - 161;
    }

    const TDEE = BMR * activityFactors[activityLevel];

    setResult(TDEE.toFixed(2));
    setOpen(true);
  };

  // const handleLanguageChange = (event) => {
  //   i18n.changeLanguage(event.target.value);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box id="pricing" sx={{ mt: { xs: 10, sm: 12 } }}>
      <Grid item xs={12} sm={6} md={3}>
        <Box
          sx={{
            maxWidth: 500,
            mx: "auto",
            backgroundColor: theme.palette.background.paper,
            padding: "16px",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: 3,
            borderRadius: "3%",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
              Insira seus Dados
            </Typography>
          </Box>
          <form onSubmit={calculateMetabolicRate} noValidate>
            <TextField
              fullWidth
              label={t("name")}
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              fullWidth
              label={t("email")}
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              fullWidth
              label={t("age")}
              type="number"
              name="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              margin="normal"
              error={!!errors.age}
              helperText={errors.age}
            />
            <TextField
              fullWidth
              label={t("height")}
              type="number"
              name="height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              margin="normal"
              error={!!errors.height}
              helperText={errors.height}
            />
            <TextField
              fullWidth
              label={t("weight")}
              type="number"
              name="weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              margin="normal"
              error={!!errors.weight}
              helperText={errors.weight}
              required
            />
            <FormControl fullWidth margin="normal" error={!!errors.gender}>
              <InputLabel>{t("gender")}</InputLabel>
              <Select name="gender" value={gender} onChange={(e) => setGender(e.target.value)} label={t("gender")}>
                <MenuItem value="">
                  <em>{t("select_gender")}</em>
                </MenuItem>
                <MenuItem value="male">{t("male")}</MenuItem>
                <MenuItem value="female">{t("female")}</MenuItem>
              </Select>
              {errors.gender && (
                <Typography variant="caption" color="error">
                  {errors.gender}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth margin="normal" error={!!errors.activityLevel} required>
              <InputLabel>{t("activity_level")}</InputLabel>
              <Select
                name="activityLevel"
                value={activityLevel}
                onChange={(e) => setActivityLevel(e.target.value)}
                label={t("activity_level")}
              >
                <MenuItem value="">
                  <em>{t("select_activity_level")}</em>
                </MenuItem>
                <MenuItem value="sedentary">{t("sedentary")}</MenuItem>
                <MenuItem value="low">{t("low")}</MenuItem>
                <MenuItem value="moderate">{t("moderate")}</MenuItem>
                <MenuItem value="intense">{t("intense")}</MenuItem>
              </Select>
              {errors.activityLevel && (
                <Typography variant="caption" color="error">
                  {errors.activityLevel}
                </Typography>
              )}
            </FormControl>
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              {t("calculate")}
            </Button>
          </form>
        </Box>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("your_daily_caloric_needs")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {name}, {t("you_need_to_consume")} <strong>{result} kcal</strong> {t("per_day_to_maintain_weight")}.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MetabolicRateCalculator;
