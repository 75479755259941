import React from "react";
import {
  Typography,
  Button,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  CardActions,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css"; // Import custom CSS
import theme from "./theme"; // Import theme if needed

function HomePage() {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    arrows: false,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  const testimonials = [
    {
      id: 1,
      name: "Matheus Muniz",
      email: "@matheusmecca",
      image:
        "https://scontent.fcgh37-1.fna.fbcdn.net/v/t39.30808-6/435085106_6731592963609063_5643281694185257755_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeEcaOKbhsGjbDMCyEZEtqTShIcgvZVQrNWEhyC9lVCs1Qv7q6wRejsltYXhzWjLkd0kPw0tfrt-6mn-UwMJbM69&_nc_ohc=uezUbhTW0IwQ7kNvgFFzgMi&_nc_ht=scontent.fcgh37-1.fna&_nc_gid=Adx6o1fbhaz9suS5Whfu-mS&oh=00_AYAIvk3OmiHkZwPJcHpvMc-Sldf4IeAjh2cno2vmYITnig&oe=66EFAB52",
      text: "Sou formado em educação física e sempre montei minhas próprias dietas, mas desde que comecei a usar QuickDiet tudo se tornou muito mais fácil e prático como se fosse eu mesmo montando só que automaticamente. Isso sem falar de novas opções que eu nem pensaria em adicionar na dieta e o app me sugeriu. Uso e recomendo pra todos meus amigos e família!",
    },
    {
      id: 2,
      name: "User Two",
      email: "user2@example.com",
      image: "https://i.pravatar.cc/150?img=2",
      text: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 3,
      name: "User Three",
      email: "user3@example.com",
      image: "https://i.pravatar.cc/150?img=3",
      text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
    },
    {
      id: 4,
      name: "User Four",
      email: "user4@example.com",
      image: "https://i.pravatar.cc/150?img=4",
      text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
    },
    {
      id: 5,
      name: "User Five",
      email: "user5@example.com",
      image: "https://i.pravatar.cc/150?img=5",
      text: "Excepteur sint occaecat cupidatat non proident, sunt in culpa.",
    },
  ];

  const pricingPlans = [
    {
      id: 1,
      title: t("free_plan_title"),
      price: t("free_plan_price"),
      description: t("free_plan_description"),
      buttonText: t("calculate_now"),
      icon: "💡", // Placeholder icon/emoji
      highlighted: false,
      action: "/calculator",
    },
    {
      id: 2,
      title: t("starter_plan_title"),
      price: t("starter_plan_price"),
      description: t("starter_plan_description"),
      buttonText: t("acquire_now"),
      icon: "🚀", // Placeholder icon/emoji
      highlighted: false,
      action: "",
    },
    {
      id: 3,
      title: t("monthly_plan_title"),
      price: t("monthly_plan_price"),
      description: t("monthly_plan_description"),
      buttonText: t("acquire_now"),
      icon: "🔥", // Placeholder icon/emoji
      highlighted: true,
      action: "",
    },
    {
      id: 4,
      title: t("annual_plan_title"),
      price: t("annual_plan_price"),
      description: t("annual_plan_description"),
      buttonText: t("acquire_now"),
      icon: "🌟", // Placeholder icon/emoji
      highlighted: false, // Highlight this plan
      originalPrice: t("annual_plan_original_price"),
      discount: t("annual_plan_discount"),
      action: "",
    },
  ];

  return (
    <Box sx={{ mt: { xs: 10, sm: 10 } }}>
      {/* Main Content */}
      <Container sx={{ mt: 4 }}>
        {/* Hero Section */}
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8} textAlign="center">
            <Typography variant="h2" gutterBottom sx={{ color: "text.primary" }}>
              {t("achieve_goals")}
            </Typography>
            <Typography variant="h6" color="textSecondary" paragraph>
              {t("discover_importance")}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              href="https://dashboard.quickdiet.app"
              sx={{
                mt: 2,
                "&:hover": {
                  backgroundColor: "#647611",
                },
              }}
              className="button-rounded"
            >
              {t("signup_signin")}
            </Button>
          </Grid>
          <Grid item xs={12} textAlign="center">
            {/* Placeholder for Healthy Food Image */}
            <Box
              component="img"
              src="https://cdn.pixabay.com/photo/2020/06/21/18/15/people-5326159_1280.jpg"
              alt="Healthy Food"
              sx={{
                width: "100%",
                // maxWidth: "600px",
                borderRadius: 2,
                mx: "auto",
                boxShadow: 3, // Adds a subtle shadow,
                objectFit: "cover",
                maxHeight: "25rem",
              }}
            />
          </Grid>
        </Grid>

        {/* Accented Section */}
        <Box
          sx={{
            mt: 8,
            p: 4,
            bgcolor: "#E0DDD7", // Stronger version of background color
            borderRadius: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t("why_choose_us")}
          </Typography>
          <Typography variant="body1">{t("benefits_description")}</Typography>
        </Box>

        {/* Benefits Section */}
        <Box id="benefits" sx={{ mt: 8, scrollMargin: "5rem" }}>
          <Typography variant="h4" gutterBottom>
            {t("healthy_diet_title")}
          </Typography>
          <Typography variant="body1" paragraph>
            {t("healthy_diet_description")}
          </Typography>

          {/* First Subsection */}
          <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                {/* Emoji/Icon and Title */}
                🏁 {t("start_by")} <span style={{ color: "#68a16c" }}>{t("calculating_metabolism")}</span>
              </Typography>
              <Typography variant="body1" paragraph>
                {t("metabolism_explanation")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Image */}
              <Box
                component="img"
                src="https://cdn.pixabay.com/photo/2016/11/19/17/20/athlete-1840437_1280.jpg"
                alt="Metabolism Calculation"
                sx={{
                  width: "100%",
                  borderRadius: 2,
                  boxShadow: 3,
                  maxHeight: { xs: 200, md: 300 },
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>

          {/* Second Subsection */}
          <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              {/* Image */}
              <Box
                component="img"
                src="https://cdn.pixabay.com/photo/2020/05/28/18/51/eat-5232255_1280.jpg"
                alt="Diet Plan"
                sx={{
                  width: "100%",
                  borderRadius: 2,
                  boxShadow: 3,
                  maxHeight: { xs: 200, md: 300 },
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Typography variant="h5" gutterBottom>
                {/* Emoji/Icon and Title */}
                📋 {t("allow_quickdiet")} <span style={{ color: "#68a16c" }}>QuickDiet</span>
                {t("plan_your_diet")}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("diet_plan_description")}
              </Typography>
            </Grid>
          </Grid>

          {/* Third Subsection */}
          <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                {/* Emoji/Icon and Title */}
                📈 {t("track_your_diet")}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("track_diet_explanation")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Image */}
              <Box
                component="img"
                src="https://cdn.pixabay.com/photo/2017/08/01/00/41/book-2562345_1280.jpg"
                alt="Diet Tracking"
                sx={{
                  width: "100%",
                  borderRadius: 2,
                  boxShadow: 3,
                  maxHeight: { xs: 200, md: 300 },
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Testimonials Section */}
        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" gutterBottom>
            {t("what_users_say")}
          </Typography>
          <Slider {...settings}>
            {testimonials.map((testimonial) => (
              <Box
                key={testimonial.id}
                sx={{
                  px: "7.5px",
                  boxSizing: "border-box",
                  cursor: "grab",
                  "&:active": {
                    cursor: "grabbing",
                  },
                }}
              >
                <Card
                  sx={{
                    height: "350px",
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "background.paper",
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar sx={{ width: 56, height: 56 }}>
                        <img
                          src={testimonial.image}
                          alt={testimonial.name}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                      </Avatar>
                    }
                    title={testimonial.name}
                    subheader={testimonial.email}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 10,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {testimonial.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Slider>
        </Box>
        {/* Pricing Section */}
        <Box id="pricing" sx={{ mt: 8 }}>
          <Typography variant="h4" gutterBottom>
            {t("pricing_title")}
          </Typography>
          <Grid
            container
            spacing={4}
            sx={{
              mt: 4,
              display: "flex",
              alignItems: "stretch",
            }}
          >
            {pricingPlans.map((plan) => (
              <Grid item xs={12} sm={6} md={3} key={plan.id}>
                <Card
                  sx={{
                    position: "relative",
                    overflow: "visible",
                    textAlign: "center",
                    paddingTop: 4,
                    border: plan.highlighted ? `2px solid ${theme.palette.primary.main}` : "none",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* Icon Circle */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: -30,
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: 60,
                      height: 60,
                      borderRadius: "50%",
                      backgroundColor: theme.palette.background.paper,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: 3,
                    }}
                  >
                    <Typography variant="h3">{plan.icon}</Typography>
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* Highlighted Title */}
                    <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
                      {plan.title}
                    </Typography>
                    {/* Description */}
                    <Typography variant="body2" paragraph>
                      {plan.description}
                    </Typography>
                  </CardContent>
                  {/* Price */}
                  <Box sx={{ mb: 2 }}>
                    {plan.originalPrice ? (
                      <>
                        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
                          {plan.price}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            textDecoration: "line-through",
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {plan.originalPrice}
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.success.main }}>
                          {plan.discount}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
                        {plan.price}
                      </Typography>
                    )}
                  </Box>
                  <CardActions sx={{ justifyContent: "center", mb: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.main,
                        },
                      }}
                      component={Link}
                      to={plan.action}
                      className="button-rounded"
                    >
                      {plan.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default HomePage;
