// src/Header.js

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "./theme";

function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State for managing the dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Handle opening the menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log(location.pathname);
  });

  // Navigation links
  const navigationLinks = [
    { title: t("benefits"), path: location.pathname === "/calculator" ? "/" : "#benefits" },
    { title: t("pricing"), path: location.pathname === "/calculator" ? "/" : "#pricing" },
    {
      title: t("signup_signin"),
      path: "https://dashboard.quickdiet.app",
    },
  ];

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "rgba(249, 247, 243, 0.9625)", boxShadow: "none", py: 1 }}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: 64,
          position: "relative",
        }}
      >
        {/* Left: Logo */}
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ textDecoration: "none", color: "#666666", minWidth: "256px" }}
        >
          {t("quickdiet")}
        </Typography>

        {/* Right Side */}
        {isMobile ? (
          // Mobile View: Hamburger Menu
          <>
            <IconButton edge="end" color="#666666" aria-label="menu" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {navigationLinks.map((item) => (
                <MenuItem
                  key={item.title}
                  onClick={handleMenuClose}
                  component={item.path.startsWith("#") ? "a" : Link}
                  to={item.path.startsWith("#") ? undefined : item.path}
                  href={item.path.startsWith("#") ? item.path : undefined}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          // Desktop View: Centered Navigation and Calculate Button
          <>
            {/* Center: Navigation */}
            <Box sx={{ display: "flex", gap: 2 }}>
              {location.pathname === "/" ? (
                <>
                  <Button component="a" href="#benefits" className="header-button">
                    {t("benefits")}
                  </Button>
                  <Button component="a" href="#pricing" className="header-button">
                    {t("pricing")}
                  </Button>
                </>
              ) : (
                <Button component="a" href="/" className="header-button">
                  {t("home")}
                </Button>
              )}
            </Box>
            <Button
              color="primary"
              variant="contained"
              href="https://dashboard.quickdiet.app"
              sx={{
                minWidth: "256px",
                "&:hover": {
                  backgroundColor: "#647611",
                },
              }}
              className="button-rounded"
            >
              {t("signup_signin")}
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
